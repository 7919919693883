@media screen and (max-width: 1600px) {
      .header-row {
            padding: 10px 15px;
      }

      .header-logo-box .header-logo {
            height: 60px;
            max-width: 60px;
      }

      .container {
            max-width: 1140px;
      }

      .header-nav-main .header-nav-ul {
            gap: 35px;
      }

      .header-nav-main .header-nav-link {
            font-size: 16px;
            line-height: 21px;
      }

      .border-btn-main .border-btn {
            padding: 10px 20px;
            font-size: 15px;
            line-height: 21px;
      }

      .primary-btn-main .primary-btn,
      .primary-btn-main .primary-btn:hover {
            padding: 10px 20px;
            font-size: 15px;
            line-height: 21px;
      }

      .hero-coin-img-wrapper {
            bottom: -24%;
      }

      .hero-copy-btn-wrapper {
            margin: 0px auto 40px;
      }

      .hero-content-main .hero-title,
      .hero-title-span {
            font-size: 55px;
            line-height: 82px;
      }

      .hero-coin-img {
            max-width: 400px;
      }

      .hero-copy-btn-wrapper .hero-copy-text {
            font-size: 17px;
            line-height: 21px;
      }

      .about-us-sec {
            padding: 80px 0px;
      }

      .about-us-wrapper .about-us-title,
      .about-us-span {
            font-size: 34px;
            line-height: 58px;
            margin-bottom: 25px;
      }

      .about-us-wrapper .about-us-para {
            font-size: 23px;
            line-height: 47px;
      }

      .tokenomics-content-box {
            padding: 10px 12px 10px 12px;
            border-radius: 15px;
      }

      .tokenomics-content-bg {
            padding: 15px;
            border-radius: 15px;
      }

      .tokenomics-content-main .tokenomics-content-title,
      .tokenomics-content-main .tokenomics-content-lable {
            font-size: 15px;
            line-height: 21px;
      }

      .tokenomics-content-color-box {
            height: 70px;
      }

      .token-chart-main .token-chart-value-text {
            font-size: 21px;
            line-height: 30px;
      }

      .tokenomics-content-main .tokenomics-content-para,
      .tokenomics-content-main .tokenomics-content-digit {
            font-size: 14px;
            line-height: 20px;
      }

      .tokenomics-wrapper .tokenomics-title {
            font-size: 43px;
            line-height: 66px;
      }

      .tokenomics-wrapper .tokenomics-heading {
            font-size: 27px;
            line-height: 37px;
      }

      .roadmap-box {
            padding: 20px 20px 20px 20px;
            height: 250px;
      }

      .tokenomics-sec-bg {
            min-height: 235px;
      }

      .roadmap-box .roadmap-content-title {
            font-size: 17px;
            line-height: 25px;
      }

      .roadmap-list .roadmap-list-item {
            font-size: 15px;
            line-height: 23px;
            margin-bottom: 5px;
      }

      .token-chart-value-box {
            top: 43%;
            left: 35%;
      }

      .token-chart-main .token-chart-value-lable {
            font-size: 18px;
            line-height: 22px;
            margin-bottom: 5px;
      }

      .roadmap-box .roadmap-content-para {
            font-size: 15px;
            line-height: 27px;
      }

      .roadmap-dot-main {
            margin-bottom: 15px;
      }

      .roadmap-dot::after {
            height: 65px;
      }

      .roadmap-slider .owl-stage-outer {
            margin-top: -150px;
            padding-top: 80px;
      }

      .roadmap-box .roadmap-year-text {
            font-size: 15px;
            line-height: 23px;
            top: -85px;
            left: -12px;
      }

      .team-sec {
            padding: 50px 0px 80px;
      }

      .team-sec .team-para {
            font-size: 17px;
            line-height: 30px;
            margin: auto auto 65px;
      }

      .team-box .team-member-name {
            margin-top: 5px;
            margin-bottom: 3px;
            font-size: 20px;
            line-height: 27px;
      }

      .team-box .team-member-position {
            font-size: 12px;
            line-height: 18px;
      }

      .team-sec .team-heading {
            font-size: 60px;
            line-height: 71px;
            margin-bottom: 15px;
      }

      .faq-sec {
            padding: 80px 0px 80px;
      }

      .roadmap-sec .roadmap-sub-title {
            font-size: 34px;
            line-height: 63px;
      }

      .roadmap-sec {
            padding: 80px 0px 50px;
      }

      .roadmap-slider-main .roadmap-slider .owl-nav .owl-next {
            right: 18%;
      }

      .team-img {
            height: 270px;
      }

      .faq-flex {
            gap: 65px;
      }

      .faq-sec .faq-sub-title {
            font-size: 33px;
            line-height: 49px;
      }

      .faq-sec .faq-heading {
            font-size: 22px;
            line-height: 30px;
            margin-bottom: 10px;
      }

      .accordian .accordian-title {
            font-size: 22px;
            line-height: 29px;
            padding-right: 65px;
      }

      .accordian-details .accodian-details-para {
            font-size: 15px;
            line-height: 28px;
      }

      .accordian .accordian-details {
            padding: 20px 30px 30px;
      }

      .accordian .MuiAccordionSummary-root::after {
            top: 20%;
            width: 50px;
            height: 56px;
      }

      .accordian .MuiAccordionSummary-root {
            padding: 25px 30px;
      }

      .faq-coin-img {
            width: 355px;
            height: 240px;
      }

      .footer-col .footer-titles {
            font-size: 112px;
            line-height: 130px;
            margin-bottom: 145px;
            margin-top: 25px;
      }

      .footer-token-img {
            height: 238px;
      }

      .footer-nav-main {
            margin-bottom: 30px;
            margin-top: 60px;
      }

      .footer-copy-main .footer-copy-right-text {
            font-size: 16px;
            line-height: 24px;
      }

      .footer {
            padding: 65px 0px 25px 0px;
      }

      .footer-copy-main-flex {
            padding-top: 20px;
      }

      .footer-social-main {
            margin-top: 60px;
            margin-bottom: 30px;
      }

      .footer-nav-ul .footer-nav-link {
            font-size: 18px;
            line-height: 27px;
      }

      .footer-nav-main .footer-nav-ul {
            gap: 30px;
      }

      .footer-copy-nav .footer-copy-nav-link {
            font-size: 16px;
            line-height: 24px;
      }

      .footer-form-main .footer-form-titles {
            font-size: 26px;
            line-height: 23px;
            margin-bottom: 15px;
      }

      .footer-form-main .footer-para {
            font-size: 15px;
            line-height: 27px;
            margin-bottom: 25px;
      }

      .top-scroll-box .top-scroll-btn {
            height: 40px;
            min-width: 40px;
      }

      .top-scroll-icon {
            height: 17px;
            width: 21px;
      }

      .tokenomics-sec-bg::before {
            height: 333px;
            width: 278px;
      }

      .tokenomics-sec-bg::after {
            height: 333px;
            width: 278px;
      }

      .roadmap-slider-main {
            padding-left: 150px;
      }
}

@media screen and (max-width: 1366px) {
      /* .roadmap-box {
            height: 250px;
      } */

}

@media screen and (max-width: 1023px) {
      .roadmap-box {
            height: 215px;
      }

      .roadmap-card-row {
            grid-template-columns: repeat(2, 1fr);
            row-gap: 100px;
      }

      .body-overflow {
            overflow: hidden;
      }

      .sticky-header {
            backdrop-filter: initial;
            filter: initial;
      }

      .header-nav-main .header-nav-link:before {
            top: 0px;
      }

      .header-nav-main .header-nav-link:after {
            bottom: 0px;
      }

      .header-right-main .mobile-menu-btn {
            display: flex;
            min-width: 30px;
            padding: 0;
            align-items: center;
      }

      .header-nav-main.mobilemenu-active {
            left: 0;
      }

      .header-nav-main {
            height: 100vh;
            padding: 20px 0;
      }

      .header-nav-main .header-nav-ul {
            flex-direction: column;
            gap: 0;
            justify-content: flex-start;
            align-items: flex-start;
      }

      .header-nav-main .header-nav-li {
            padding: 10px 20px;
            width: 100%;
      }

      .header-nav-main {
            background: #FDF2EC;
            bottom: 0;
            left: -500px;
            max-width: 300px;
            position: fixed;
            top: 0;
            height: 100%;
            transition: all .5s ease;
            width: 100%;
            z-index: 9999;
            -webkit-transition: all .5s ease;
            -moz-transition: all .5s ease;
            -ms-transition: all .5s ease;
            -o-transition: all .5s ease;
      }

      .header-row {
            padding: 10px 15px;
      }

      .header-logo-box .header-logo {
            height: 45px;
            max-width: 45px;
      }

      .hero-coin-img {
            max-width: 290px;
      }

      .hero-content-main {
            padding-top: 70px;
      }

      .hero-content-main .hero-title,
      .hero-title-span {
            font-size: 29px;
            line-height: 55px;
      }

      .hero-section {
            min-height: 75vh;
            padding-bottom: 150px;
      }

      .about-us-sec {
            padding: 30px 0px 30px;
      }

      .about-us-wrapper .about-us-para {
            font-size: 17px;
            line-height: 35px;
      }

      .tokenomics-sec-bg {
            min-height: 145px;
            padding: 50px 0px 0;
      }

      .tokenomics-sec-bg::before {
            height: 223px;
            width: 152px;
      }

      .tokenomics-sec-bg::after {
            height: 223px;
            width: 152px;
      }

      .tokenomics-wrapper .tokenomics-heading {
            font-size: 24px;
            line-height: 30px;
      }

      .tokenomics-wrapper .tokenomics-title {
            font-size: 33px;
            line-height: 51px;
      }

      .tokenomics-row {
            padding-top: 0;
            padding-left: 0;
            padding-right: 0;
      }

      .tokenomics-sec {
            padding: 0px 0px 50px;
      }

      .tokenomics-content-color-box {
            height: 50px;
            width: 10px;
            border-radius: 5px;
      }

      .tokenomics-content-inner-flex {
            gap: 5px;
      }

      .tokenomics-content-main .tokenomics-content-para,
      .tokenomics-content-main .tokenomics-content-digit {
            font-size: 12px;
            line-height: 20px;
      }

      .tokenomics-content-main .tokenomics-content-title,
      .tokenomics-content-main .tokenomics-content-lable {
            font-size: 13px;
            line-height: 18px;
            margin-bottom: 7px;
      }

      .token-chart-main .token-chart-value-lable {
            font-size: 13px;
            line-height: 17px;
      }

      .token-chart-main .token-chart-value-text {
            font-size: 13px;
            line-height: 22px;
      }

      .roadmap-sec {
            padding: 30px 0px 30px;
      }

      /* .roadmap-box {
            height: 191px;
      } */

      .roadmap-sec .roadmap-sub-title {
            font-size: 23px;
            line-height: 39px;
            margin-bottom: 28px;
      }

      .team-sec .team-para {
            margin: auto auto 15px;
      }

      .roadmap-slider-main .roadmap-slider .owl-nav .owl-prev {
            top: 0;
            right: 24.8%;
      }

      .roadmap-slider-main .roadmap-slider .owl-nav .owl-next {
            top: 0;
            right: 14%;
      }

      .roadmap-slider-main .roadmap-slider .owl-nav .owl-prev,
      .roadmap-slider-main .roadmap-slider .owl-nav .owl-next,
      .roadmap-slider-main .roadmap-slider .owl-nav .owl-prev:hover,
      .roadmap-slider-main .roadmap-slider .owl-nav .owl-next:hover {
            width: 30px;
            height: 30px;
      }

      .roadmap-slider-main {
            padding-left: 15px;
      }

      .team-row {
            grid-template-columns: repeat(3, 1fr);
      }

      .team-box {
            margin: auto;
      }

      .team-sec {
            padding: 0px 0px 50px;
      }

      .team-sec .team-heading {
            font-size: 40px;
            line-height: 51px;
      }

      .faq-sec {
            padding: 30px 0px 50px;
      }

      .accrodian-main .accordian {
            margin-bottom: 15px;
      }

      .faq-flex {
            gap: 30px;
            flex-direction: column;
      }

      .faq-left-main {
            max-width: 100%;
            flex: 0 0 100%;
      }

      .faq-sec .faq-sub-title {
            font-size: 22px;
            line-height: 39px;
      }

      .accordian .MuiAccordionSummary-root::after {
            width: 25px;
            height: 25px;
            right: 14px;
            top: 23%;
      }

      .accordian .accordian-title {
            font-size: 16px;
            line-height: 21px;
      }

      .accordian .MuiAccordionSummary-root {
            padding: 15px 15px;
      }

      .accordian .accordian-details {
            padding: 0px 15px 15px;
      }

      .accordian-details .accodian-details-para {
            font-size: 13px;
            line-height: 25px;
      }

      .faq-coin-img {
            width: 209px;
            height: 136px;
      }

      .faq-coin-img-main {
            right: 3%;
            left: initial;
      }

      .footer {
            padding: 30px 0px 25px 0px;
      }

      .footer-col .footer-titles {
            font-size: 50px;
            line-height: 59px;
            margin-bottom: 22px;
            margin-top: 0;
            text-align: center;
      }

      .footer-nav-ul .footer-nav-link {
            font-size: 17px;
      }

      .footer-nav-main .footer-nav-ul {
            row-gap: 10px;
            justify-content: center;
            align-items: center;
            column-gap: 30px;
      }

      .footer-nav-main {
            margin-bottom: 10px;
            margin-top: 10px;
      }

      .footer-form-main .footer-form-titles {
            font-size: 21px;
            line-height: 33px;
            margin-bottom: 10px;
            text-align: center;
      }

      .footer-form-main .footer-para {
            font-size: 20px;
            line-height: 28px;
            margin-bottom: 20px;
            text-align: center;
            max-width: 100%;
      }

      .footer-form-flex {
            grid-template-columns: repeat(2, 1fr);
            gap: 35px;
            margin-bottom: 10px;
      }

      .user-form-group .user-form-control input {
            padding: 7px 19px;
            font-size: 15px;
            line-height: 20px;
      }

      .user-form-group .user-form-control input::placeholder {
            font-size: 15px;
            line-height: 20px;
      }

      .footer-content-main .footer-btn,
      .footer-content-main .footer-btn:hover {
            font-size: 16px;
            line-height: 24px;
            padding: 8px 20px;
      }

      .footer-social-main {
            margin-top: 20px;
            margin-bottom: 20px;
            justify-content: center;
      }

      .footer-social-main .footer-social-titles {
            font-size: 18px;
            line-height: 23px;
      }

      .top-scroll-box {
            bottom: 45px;
            right: 30px;
      }

      .mobile-logo-main {
            display: block;
      }

      .hero-coin-img-wrapper {
            bottom: -37%;
      }

      .hero-token-wrapper {
            margin: 25px 0 30px;
      }

}


@media screen and (max-device-width: 1023px) and (orientation : landscape) {
      .tokenomics-sec-bg::before {
            height: 269px;
            width: 159px;
      }

      .tokenomics-sec-bg::after {
            height: 269px;
            width: 159px;
      }


}


@media screen and (max-device-width: 767px) and (orientation: landscape) {

      .tokenomics-sec-bg::before {
            height: 269px;
            width: 159px;
      }

      .tokenomics-sec-bg::after {
            height: 269px;
            width: 159px;
      }
}

@media (max-width: 767px) {
      .roadmap-card-row {
            grid-template-columns: repeat(1, 1fr);
            gap: 100px;
      }

      .roadmap-box {
            height: auto;
      }

      .hero-content-main {
            padding-top: 65px;
      }

      .header-right-main {
            gap: 5px;
      }

      .border-btn-main .border-btn {
            padding: 6px 14px;
            font-size: 11px;
            line-height: 17px;
      }

      .primary-btn-main .primary-btn,
      .primary-btn-main .primary-btn:hover {
            padding: 6px 14px;
            font-size: 11px;
            line-height: 17px;
      }

      .hero-content-main .hero-title,
      .hero-title-span {
            font-size: 23px;
            line-height: 41px;
      }

      .hero-token-wrapper {
            margin: 30px 0 30px;
      }

      .hero-content-wrapper .hero-heading {
            font-size: 14px;
            line-height: 20px;
      }

      .hero-content-wrapper {
            margin: 70px auto 14px;
      }

      .hero-copy-btn-wrapper .hero-copy-text {
            font-size: 14px;
            line-height: 17px;
      }

      .hero-copy-btn-wrapper {
            margin: 0px auto 0px;
            padding: 5px 5px 5px 9px;
      }

      .hero-section {
            min-height: 65vh;
            padding-bottom: 150px;
      }

      .hero-coin-img {
            max-width: 225px;
      }

      .hero-coin-img-wrapper {
            bottom: -42%;
      }

      .about-us-sec {
            padding: 30px 0px 30px;
      }


      .about-us-wrapper .about-us-title,
      .about-us-span {
            font-size: 19px;
            line-height: 34px;
            margin-bottom: 25px;
      }

      .about-us-wrapper .about-us-para {
            font-size: 15px;
            line-height: 30px;
      }

      .tokenomics-sec-bg {
            min-height: 145px;
            padding: 50px 0px 0;
      }

      .tokenomics-wrapper .tokenomics-heading {
            font-size: 18px;
            line-height: 22px;
      }

      .tokenomics-wrapper .tokenomics-title {
            font-size: 26px;
            line-height: 42px;
      }

      .tokenomics-row {
            padding-top: 0;
            padding-left: 0;
            padding-right: 0;
      }

      .tokenomics-sec {
            padding: 0px 0px 30px;
      }

      .tokenomics-content-inner-flex {
            gap: 7px;
      }

      .tokenomics-sec-bg:after,
      .tokenomics-sec-bg:before {
            height: 212px;
            width: 68px;
      }

      .tokenomics-sec-bg::before,
      .tokenomics-sec-bg::after {
            top: 30%;
      }

      .tokenomics-content-main .tokenomics-content-para,
      .tokenomics-content-main .tokenomics-content-digit {
            font-size: 12px;
            line-height: 20px;
      }

      .tokenomics-content-main .tokenomics-content-title,
      .tokenomics-content-main .tokenomics-content-lable {
            margin-bottom: 0;
      }

      .token-chart-main .token-chart-value-lable {
            font-size: 13px;
            line-height: 17px;
      }

      .token-chart-main .token-chart-value-text {
            font-size: 13px;
            line-height: 22px;
      }

      .roadmap-box .roadmap-content-title {
            font-size: 15px;
            line-height: 22px;
      }

      .roadmap-box .roadmap-content-para {
            font-size: 12px;
            line-height: 24px;
      }

      .roadmap-sec {
            padding: 30px 0px 30px;
      }

      .roadmap-sec .roadmap-heading {
            font-size: 22px;
            line-height: 28px;
      }

      .roadmap-sec .roadmap-sub-title {
            font-size: 16px;
            line-height: 30px;
      }

      .roadmap-slider-main .roadmap-slider .owl-nav .owl-prev {
            top: 8%;
            right: 11%;
      }

      .roadmap-slider-main .roadmap-slider .owl-nav .owl-next {
            top: 8%;
            right: -2%;
      }

      .roadmap-slider-main .roadmap-slider .owl-nav .owl-prev,
      .roadmap-slider-main .roadmap-slider .owl-nav .owl-next,
      .roadmap-slider-main .roadmap-slider .owl-nav .owl-prev:hover,
      .roadmap-slider-main .roadmap-slider .owl-nav .owl-next:hover {
            width: 30px;
            height: 30px;
      }

      .roadmap-slider-main {
            padding-left: 15px;
            padding-right: 15px;
      }

      .team-sec .team-para {
            font-size: 16px;
            line-height: 29px;
            margin: auto auto 30px;
      }

      .team-row {
            grid-template-columns: repeat(1, 1fr);
            gap: 30px;
      }

      .team-sec {
            padding: 0px 0px 30px;
      }

      .team-box {
            margin: auto;
      }

      .faq-sec {
            padding: 30px 0px 75px;
      }

      .faq-flex {
            gap: 30px;
            flex-direction: column;
      }

      .faq-left-main {
            max-width: 100%;
            flex: 0 0 100%;
      }

      .faq-sec .faq-sub-title {
            font-size: 18px;
            line-height: 30px;
      }

      .accrodian-main .accordian {
            margin-bottom: 15px;
      }


      .accordian .accordian-title {
            font-size: 14px;
            line-height: 22px;
      }

      .accordian .MuiAccordionSummary-root {
            padding: 15px 15px;
      }

      .accordian .accordian-details {
            padding: 0px 15px 15px;
      }

      .accordian-details .accodian-details-para {
            font-size: 13px;
            line-height: 25px;
      }

      .faq-coin-img {
            width: 135px;
            height: 95px;
      }

      .faq-coin-img-main {
            right: 3%;
            bottom: -4%;
            left: initial;
      }

      .footer {
            padding: 30px 0px 25px 0px;
      }

      .footer-col .footer-titles {
            font-size: 50px;
            line-height: 60px;
            margin-bottom: 21px;
            margin-top: 0;
            text-align: center;
      }

      .footer-nav-ul .footer-nav-link {
            font-size: 15px;
            line-height: 21px;
      }

      .footer-nav-main .footer-nav-ul {
            row-gap: 10px;
            justify-content: center;
            align-items: center;
            column-gap: 15px;
      }

      .footer-nav-main {
            margin-bottom: 10px;
            margin-top: 0px;
      }

      .footer-token-img {
            height: auto;
      }

      .footer-form-main .footer-form-titles {
            font-size: 18px;
            line-height: 26px;
            margin-bottom: 15px;
            text-align: center;
      }

      .footer-form-main .footer-para {
            font-size: 12px;
            line-height: 22px;
            margin-bottom: 20px;
            text-align: center;
      }

      .footer-form-flex {
            grid-template-columns: repeat(1, 1fr);
            gap: 5px;
            margin-bottom: 10px;
      }

      .user-form-group .user-form-control input {
            padding: 7px 19px;
            font-size: 15px;
            line-height: 20px;
      }

      .user-form-group .user-form-control input::placeholder {
            font-size: 15px;
            line-height: 20px;
      }

      .footer-content-main .footer-btn,
      .footer-content-main .footer-btn:hover {
            font-size: 16px;
            line-height: 24px;
            padding: 8px 20px;
      }

      .footer-social-main {
            margin-top: 20px;
            margin-bottom: 20px;
            justify-content: center;
      }

      .footer-social-main .footer-social-titles {
            font-size: 15px;
            line-height: 23px;
      }

      .footer-copy-main-flex {
            flex-direction: column;
            justify-content: center;
            gap: 15px;
      }

      .footer-copy-main .footer-copy-right-text {
            font-size: 13px;
            line-height: 21px;
      }

      .footer-copy-nav .footer-copy-nav-link {
            font-size: 13px;
            line-height: 21px;
      }

      .top-scroll-box {
            bottom: 45px;
            right: 30px;
      }

      .order-2 {
            order: 2;
      }

      .team-img-box::before {
            display: none;
      }

}

@media (max-width: 549px) {
      .accordian .accordian-title {
            font-size: 12px;
            line-height: 20px;
      }

      .hero-coin-img-wrapper {
            bottom: -42%;
      }
}

@media (max-width: 374px) {}